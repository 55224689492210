import { createSlice } from '@reduxjs/toolkit'
import { auth, database } from 'utils/firebase'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import axios from 'axios'
import moment from 'moment'
import { getIP } from '../helper/locationHelpers'
// ------------------------------------
// State
// ------------------------------------

const initialState = {
    checked: false,
    sessionKey: null,
    loggedIn: false,
    me: {},
    authMe: null,
    startChat: false,
    openConversationNode: null,
    queued: null,
    messages: [],
    doctorProfile: {},
    socialInfo: {},
    newUser: false,
    sqlData: null,
    thirdPartyData: null,
    sqlThirdPartyData:null,
    thirdPartyHandled: false,
    issueInfo: null,
    userPatientData: null,
    queueTime: null,
    openConversations: null,
    skipHealthCard: false,
    isThirdPartyUser: false,
    selectedClinic: null,
    clinics: null,
    stripeCustomer: null,
    pharmaInfo: null,
    campaignSource: null,
    closeChat:false,
    showDownloadApp: false

}
let userProfileRef = null
let userOpenConversationRef = null
let userConversationRef = null
let conversationRef = null
let latestQueueRef = null
let openConversationsRef = null
// ------------------------------------
// Slices
// -----------------------------------

const slice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setMe: (state, action) => ({
            ...state,
            me: action.payload.me || state.me,
            loggedIn: action.payload.loggedIn,
            checked: action.payload.checked,
        }),
        setAuthMe: (state, action) => ({
            ...state,
            authMe: action.payload
        }),
        setOpenConversation: (state, action) => ({
            ...state,
            openConversationNode: action.payload,
        }),
        resetState: (state, action) => ({
            ...state,
            loggedIn: false,
            startChat: false,
            openConversationNode: null,
            queued: null,
            messages: [],
            doctorProfile: {},
            socialInfo: {},
            newUser: false,
            sqlData: null,
            sqlThirdPartyData:null,
            thirdPartyHandled: false,
            userPatientData: null,
            queueTime: null,
            openConversations: null,
            skipHealthCard: false,
            isThirdPartyUser: false,
            selectedClinic: null,
            clinics: null,
            stripeCustomer: null,
            isEmailSignIn: false
        }),
        setPharmaInfo: (state, action) => ({
            ...state,
            pharmaInfo: action.payload,
        }),
        setCloseChat:(state, action) => ({
            ...state,
            closeChat: action.payload,
        }),
        setShowDownloadApp:(state, action) => ({
            ...state,
            showDownloadApp: action.payload,
        }),
        setCampaignSource: (state, action) => ({
            ...state,
            campaignSource: action.payload,
        }),
        setSessionKey: (state, action) => ({
            ...state,
            sessionKey: action.payload,
        }),
        setLoggedIn: (state, action) => ({
            ...state,
            loggedIn: action.payload,
        }),
        setChecked: (state, action) => ({
            ...state,
            checked: action.payload,
        }),
        setStartChat: (state, action) => ({
            ...state,
            startChat: action.payload,
        }),
        setQueueChatStatus: (state, action) => ({
            ...state,
            queued: action.payload,
        }),
        addConversationsMessage: (state, action) => {
            const res = {
                ...state,
            }
            const messages = state.messages ? [...state.messages] : []
            messages.push(action.payload)
            res.messages = messages
            console.log("messages ", messages)
            return res

        },
        resetMessages: (state, action) => ({
            ...state,
            messages: null,

        }),
        setDoctorProfile: (state, action) => ({
            ...state,
            doctorProfile: action.payload,
        }),
        setSocialInfo: (state, action) => ({
            ...state,
            socialInfo: action.payload.socialInfo,
        }),
        setNewUser: (state, action) => ({
            ...state,
            newUser: action.payload,
        }),
        setSqlData: (state, action) => ({
            ...state,
            sqlData: action.payload,
        }),
        setIssueInfo: (state, action) => ({
            ...state,
            issueInfo: action.payload,
        }),
        setUserPatientData: (state, action) => ({
            ...state,
            userPatientData: action.payload,
        }),
        setQueueTime: (state, action) => ({
            ...state,
            queueTime: action.payload,
        }),
        setOpenConversations: (state, action) => ({
            ...state,
            openConversations: action.payload,
        }),
        setSelectedClinic: (state, action) => ({
            ...state,
            selectedClinic: action.payload,
        }),
        setClinics: (state, action) => ({
            ...state,
            clinics: action.payload,
        }),
        setStripeCustomer: (state, action) => ({
            ...state,
            stripeCustomer: action.payload,
        }),
        setThirdPartyData: (state, action) => ({
            ...state,
            thirdPartyData: action.payload,
        }),
        setIsEmailSignIn: (state, action) => ({
            ...state,
            isEmailSignIn: action.payload,
        }),
        setSqlThirdPartyData:(state, action) => ({
            ...state,
            sqlThirdPartyData: action.payload,
        }),
        setThirdPartyHandled: (state, action) => ({
            ...state,
            thirdPartyHandled: action.payload,
        }),
        setSkipHealthCard: (state, action) => ({
            ...state,
            skipHealthCard: action.payload,
        }),
        setIsThirdPartyUser: (state, action) => ({
            ...state,
            isThirdPartyUser: action.payload,
        }),

    },
})

// ------------------------------------
// Actions
// -----------------------------------

export const setPharmaInfo = (data) => (dispatch) => dispatch(slice.actions.setPharmaInfo(data))
export const setCampaignSource = (data) => (dispatch) => dispatch(slice.actions.setCampaignSource(data))
export const setShowDownloadApp = (data)=> (dispatch) => dispatch(slice.actions.setShowDownloadApp(data))
export const setCloseChat = (data)=> (dispatch) => dispatch(slice.actions.setCloseChat(data))
export const setSessionKey = (data) => (dispatch) => dispatch(slice.actions.setSessionKey(data))
export const setIsThirdPartyUser = (data) => (dispatch) => dispatch(slice.actions.setIsThirdPartyUser(data))
export const setSkipHealthCard = (data) => (dispatch) => dispatch(slice.actions.setSkipHealthCard(data))
export const setThirdPartyHandled = (data) => (dispatch) => dispatch(slice.actions.setThirdPartyHandled(data))
export const setThirdPartyData = (data) => (dispatch) => dispatch(slice.actions.setThirdPartyData(data))
export const setSqlThirdPartyData = (data) => (dispatch) => dispatch(slice.actions.setSqlThirdPartyData(data))
export const setStripeCustomer = (data) => (dispatch) => dispatch(slice.actions.setStripeCustomer(data))
export const setSelectedClinic = (data) => (dispatch) => dispatch(slice.actions.setSelectedClinic(data))
export const setClinics = (data) => (dispatch) => dispatch(slice.actions.setClinics(data))
export const setUserPatientData = (data) => (dispatch) => dispatch(slice.actions.setUserPatientData(data))
export const setIssueInfo = (data) => (dispatch) => dispatch(slice.actions.setIssueInfo(data))

export const setSqlData = (data) => (dispatch) => dispatch(slice.actions.setSqlData(data))

export const setNewUser = (status) => (dispatch) => dispatch(slice.actions.setNewUser({newUser: status}))

export const setSocialInfo = (data) => (dispatch) => dispatch(slice.actions.setSocialInfo({socialInfo: data}))

export const startChat = () => (dispatch) => dispatch(slice.actions.setStartChat(true))

export const setQueueTime = () => (dispatch) => {

    latestQueueRef = database.ref(`latestExistQueueChat/`)
    latestQueueRef.off()
    latestQueueRef.on('value', latestQueueSnap => {
        if (latestQueueSnap.exists()) {
            const queueTime = Math.ceil((latestQueueSnap.val().waitEndTime - latestQueueSnap.val().start_time) / 60000)// time in minutes
            dispatch(slice.actions.setQueueTime(queueTime))
        }
    })
}

export const setOpenConversations = () => (dispatch) => {

    openConversationsRef = database.ref(`openconversations/`).orderByChild('queued').equalTo(true)
    openConversationsRef.off()
    openConversationsRef.on('value', openConversationsSnap => {
        if (openConversationsSnap.exists()) {
            const openConversationsSnapKeys = Object.keys(openConversationsSnap.val())
            const openConversationsData = openConversationsSnapKeys.map(key => openConversationsSnap.val().queued ? {
                ...openConversationsSnap.val()[key],
                id: key,
            } : {
                ...openConversationsSnap.val()[key],
                id: key,
            }).filter(value => value)
            dispatch(slice.actions.setOpenConversations(openConversationsData))
        }
    })
}

const chatListener = (uid, dispatch) => {

    userConversationRef = database.ref(`users/${uid}/conversations`)
    userConversationRef.off()
    userConversationRef.on('value', (userConvo) => {
        if (userConvo.exists()) {
            const conversations = userConvo.val()

            const node = conversations.clinical_chat ? `/openClinicalConversations/${conversations.clinic_id}/${conversations.location}` : `/openconversations/${conversations.location}`
            userOpenConversationRef = database.ref(node)
            userOpenConversationRef.off()
            userOpenConversationRef.on('value', (openConvoSnap) => {
                if (openConvoSnap.val()) {
                    dispatch(slice.actions.setOpenConversation({
                        ...openConvoSnap.val(),
                        id: conversations.location,
                    }))
                    dispatch(slice.actions.setQueueChatStatus(openConvoSnap.val().queued))
                    dispatch(slice.actions.setStartChat(!openConvoSnap.val().queued))
                } else {
                    dispatch(slice.actions.setOpenConversation(null))
                    dispatch(slice.actions.setQueueChatStatus(false))
                    dispatch(slice.actions.setStartChat(false))
                    dispatch(slice.actions.resetMessages())
                }
            })
        }else{
            dispatch(slice.actions.setOpenConversation(null))
            dispatch(slice.actions.setQueueChatStatus(false))
            dispatch(slice.actions.setStartChat(false))
            dispatch(slice.actions.resetMessages())
        }
    })

}
const getSQLTPData = (dispatch,  userId ) => {
    /*axios({
        method: 'GET',
        url: `https://northamerica-northeast1-yourdoctorsonline-prod.cloudfunctions.net/api/0.1/campaigns/thirdPartyData?id=${userId}`,
        mode: 'cors'
    })
        .then(response => {
            // console.log('patients response', response)
            dispatch(slice.actions.setSqlThirdPartyData(response?.data?.user))
        })
        .catch(error => {
            console.log('patients error', error)
        })*/
}

export const getSQLThirdPartyData = (userId) => (dispatch) => {
    getSQLTPData(dispatch,userId )
}

const fetchSqlData = (email, dispatch, payload) => {
    /* axios({
        method: 'GET',
        url: `https://northamerica-northeast1-yourdoctorsonline-prod.cloudfunctions.net/api/0.1/admin_users/getuser?email=${encodeURIComponent(email)}`,
        mode: 'cors',
    })
        .then(response => {
            // console.log('getuser response ', response)
            if (response?.data?.user) {
                dispatch(slice.actions.setSqlData(response?.data?.user))
                getSQLTPData(dispatch, response?.data?.user?.id)
                axios({
                    method: 'GET',
                    url: `https://northamerica-northeast1-yourdoctorsonline-prod.cloudfunctions.net/api/0.1/patients/${response?.data?.user?.id}`,
                    mode: 'cors',
                })
                    .then(response => {
                        // console.log('patients response', response)
                        dispatch(slice.actions.setUserPatientData(response?.data?.patient))

                    })
                    .catch(error => {
                        console.log('patients error', error)
                    })

            } else {
                //dispatch(slice.actions.setMe(payload))
                dispatch(slice.actions.setNewUser(true))
            }
        }).catch(error => {
        console.log('error', error)
    }) */
}

const locationUpdate = async (uid, userJSON, thirdPartyData, sessionKey) => {
    if(uid){
        const updates = {};
        await getIP(async ip => {
            if(ip)updates[`users/${uid}/current_sign_at_ip`] = ip || null;
            if(ip)updates[`users/${uid}/last_sign_at_ip`] = ip || null;
            const fetchUserAddressPayload = {
                ip: ip || null,
                userID: uid
            }
            console.log("fetchUserAddressPayload ", fetchUserAddressPayload)
            let res = null;
            try{
                res = await axios({
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    url: `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_NORTH_EAST_URL}/mobileApp-fetchUserAddress`,
                    data: JSON.stringify(fetchUserAddressPayload)
                })
            }
            catch (e){
                console.log("mobileApp-fetchUserAddress failed", e.message)
            }
            const locationData = res?.data?.data || {}
            if(thirdPartyData?.address || locationData?.display_name) updates[`users/${uid}/complete_address`] = thirdPartyData?.address ||  locationData?.display_name || userJSON?.complete_address || null;
            if(thirdPartyData?.country || locationData?.country) updates[`users/${uid}/country`] = thirdPartyData?.country || locationData?.country || userJSON?.country || null;
            if(thirdPartyData?.city || locationData?.city) updates[`users/${uid}/city`] = thirdPartyData?.city || locationData?.city || userJSON?.city || null;
            if(thirdPartyData?.postalCode || locationData?.postcode) updates[`users/${uid}/postcode`] = thirdPartyData?.postalCode || locationData?.postcode || userJSON?.postcode || userJSON?.postalcode || null;
            if(thirdPartyData?.postalCode || locationData?.postcode) updates[`users/${uid}/postalcode`] = thirdPartyData?.postalCode || locationData?.postcode || userJSON?.postcode || userJSON?.postalcode || null;
            if(thirdPartyData?.state || locationData?.province) updates[`users/${uid}/state`] = thirdPartyData?.state || locationData?.province || userJSON?.state || null;
            if (Object.keys(updates).length>0){
                database.ref().update(updates).then((d)=>{console.log("loginWithThirdParty address data ", d)}).catch(e=>{
                    console.log("loginWithThirdParty address e ", e)
                    if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/loggedInLocationDataError`).set({
                        thirdParty:true,
                        partnerId:thirdPartyData?.partnerId? parseInt(thirdPartyData?.partnerId) : null,
                        email: thirdPartyData.email || null,
                        error:e?.message || e || null
                    }).then(()=>{}).catch((error)=>{console.log("error", error)});
                    if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
                })
            }
        })
    }

}

const transformToNestedJson = (flatJson) => {
    const nestedJson = {};

    Object.keys(flatJson).forEach((key) => {
        // Split the key to separate the user ID and attribute
        const [path, attribute] = key.split('/').slice(1); // Remove the first part 'users' and split the rest

        // Ensure the user ID entry exists
        if (!nestedJson[path]) {
            nestedJson[path] = {};
        }

        // Add the attribute to the specific user ID
        nestedJson[path][attribute] = flatJson[key];
    });

    return nestedJson;
};

const storeUserData = async (userJSON, thirdPartyData, uid, sessionKey) => {
    const {
        authorizationKey,
        partnerName,
        partnerId,
        email,
        name,
        bloodGroup,
        notes,
        gender,
        dateOfBirth,
        type,
        expiry,
        phoneNumber,
        address,
        postalCode,
    } = thirdPartyData || {}
    let offset = (new Date()).getTimezoneOffset()
    offset = offset || 0
    offset = (offset * -1) / 60
    const {city, country, state, postalcode, complete_address, phone_number} = userJSON;
    const basicInfo = (city && country && state && postalcode && complete_address );
    const updates = {};
    if(thirdPartyData?.partnerId) updates[`users/${uid}/partnerId`] = thirdPartyData?.partnerId? parseInt(thirdPartyData?.partnerId) : null;
    if(thirdPartyData?.partnerName) updates[`users/${uid}/partnerName`] = thirdPartyData?.partnerName || null;
    if(thirdPartyData?.phoneNumber && !phone_number) updates[`users/${uid}/phone_number`] = thirdPartyData?.phoneNumber;
    if(thirdPartyData?.notes) updates[`users/${uid}/notes`] = thirdPartyData?.notes;
    updates[`users/${uid}/expiry`] = userJSON?.expiry || parseInt(thirdPartyData?.expiry || 0)
    updates[`users/${uid}/type`] = userJSON?.type || thirdPartyData?.type || null;
    updates[`users/${uid}/email`] = userJSON?.email || thirdPartyData?.email;
    updates[`users/${uid}/platform`] = userJSON?.platform || "web";
    updates[`users/${uid}/ban`] = userJSON?.ban || "no";
    updates[`users/${uid}/device_id`] = userJSON?.device_id || `web_${uid}`;
    updates[`users/${uid}/heightCm`] = userJSON?.heightCm || "";
    updates[`users/${uid}/weightKg`] = userJSON?.weightKg || "";
    updates[`users/${uid}/runningWalking`] = userJSON?.runningWalking || "";
    updates[`users/${uid}/gmtOffset`] = userJSON?.gmtOffset || offset || null;
    updates[`users/${uid}/third_party_blood_group`] = userJSON?.third_party_blood_group ||  bloodGroup !== 'null' && bloodGroup !== null && bloodGroup !== '' ? bloodGroup : null;
    updates[`users/${uid}/partnerFCMToken`] = userJSON?.partnerFCMToken || thirdPartyData?.partnerFCMToken || null;
    updates[`users/${uid}/partnerUserID`] = userJSON?.partnerUserID || thirdPartyData?.partnerUserID || null;
    updates[`users/${uid}/name`] = userJSON?.name || userJSON?.userName || thirdPartyData?.name;
    updates[`users/${uid}/userName`] = userJSON?.name || userJSON?.userName || thirdPartyData?.name;
    updates[`users/${uid}/gender`] = userJSON?.gender || thirdPartyData?.gender || null;
    updates[`users/${uid}/DOB`] = userJSON?.DOB || dateOfBirth? moment(dateOfBirth, 'YYYY-MM-DD').format('MMMM DD YYYY') : null;
    console.log("basicInfoPage (city && country && state && postalcode && complete_address )", !!(city && country && state && postalcode && complete_address ))
    if(!basicInfo){
        await locationUpdate(uid, userJSON, thirdPartyData, sessionKey)
    }
    console.log("storeUserData updates ", updates)
    const updatesPayload = transformToNestedJson(updates);
    if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/loggedInData`).set({
        thirdParty:true,
        partnerId:thirdPartyData?.partnerId? parseInt(thirdPartyData?.partnerId || 0) : null,
        email: thirdPartyData.email || null,
        firebaseData: { ...updatesPayload[uid] }
    }).then(()=>{}).catch((error)=>{console.log("error", error)});
    if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});

    await database.ref().update(updates)
}

const loginWithThirdPartyV2 = (thirdPartyData,sessionKey, dispatch) => {
    console.log("loginWithThirdPartyV2 thirdPartyData", thirdPartyData)
    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        url: `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/getUserByEmail`,
        mode: 'cors',
        data: JSON.stringify({
            "email": thirdPartyData.email
        }),
    })
        .then(async res => {
            console.log("getUserByEmail data ", res?.data);
            try{
                const data = await auth.signInWithEmailAndPassword(thirdPartyData.email, 'Welcome!')
                let userData = null;
                userData = await database.ref(`/users/${data?.user?.uid}/`).once('value');
                const userJSON = userData.exists()? userData.toJSON(): {};
                await storeUserData(userJSON, thirdPartyData, data?.user?.uid, sessionKey )
            }catch (e) {
                console.log("loginWithThirdParty address e ", e)
                if(sessionKey){

                    database.ref(`/sessions/${sessionKey}/events/${Date.now()}/loggedInDataError`).set({
                        thirdParty:true,
                        partnerId:thirdPartyData?.partnerId? parseInt(thirdPartyData?.partnerId) : null,
                        email: thirdPartyData.email || null,
                        error:e?.message || e || null
                    }).then(()=>{}).catch((error)=>{console.log("error", error)});
                    database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
                }
                return dispatch(
                    slice.actions.setMe({
                        loggedIn: false,
                        checked: true,
                        me: {},
                    }),
                )
            }
        })
        .catch(async error => {
            console.log("getUserByEmail error ", error?.data);
            try{
                const user = await auth.createUserWithEmailAndPassword(thirdPartyData.email, 'Welcome!')
                const {uid} = user.user;
                return await storeUserData({}, thirdPartyData, uid, sessionKey );
            }catch (e) {
                if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/signedUpError`).set({
                    thirdParty:true,
                    partnerId:thirdPartyData?.partnerId? parseInt(thirdPartyData?.partnerId) : null,
                    email: thirdPartyData.email || null,
                    error: error?.message || error || null
                }).then(()=>{}).catch((error)=>{console.log("error", error)});
                if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
                return dispatch(
                    slice.actions.setMe({
                        loggedIn: false,
                        checked: true,
                        me: {},
                    }),
                )
            }
        })
}

export const setupLogin = (me) => (dispatch, getState) => {
    const thirdPartyData = getState()?.app?.thirdPartyData;
    const sessionKey = getState()?.app?.sessionKey;
    if(me?.uid){
        if(me?.email && thirdPartyData?.email && (me?.email !== thirdPartyData?.email)){
            console.log("auth.signOut() trigggered")
            auth.signOut().then(data=>{
                if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/thirdPartySignOut`).set({
                    thirdParty:true,
                    partnerId:thirdPartyData?.partnerId? parseInt(thirdPartyData?.partnerId) : null,
                    email: thirdPartyData?.email || null,
                    previousEmail: me?.email || null
                }).then(()=>{}).catch((error)=>{console.log("error", error)});
                if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
                return dispatch(slice.actions.resetState())
            }).catch(error=>{
                if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/thirdPartySignOutFailed`).set({
                    thirdParty:true,
                    partnerId:thirdPartyData?.partnerId? parseInt(thirdPartyData?.partnerId) : null,
                    email: thirdPartyData?.email || null,
                    previousEmail: me?.email || null,
                    error: error?.message || null
                }).then(()=>{}).catch((error)=>{console.log("error", error)});
                if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
            })
        }
        chatListener(me?.uid, dispatch)
        if(userProfileRef) userProfileRef.off()
        userProfileRef = database.ref(`users/${me?.uid}`)
        userProfileRef.on('value', (user) => {
            if (!user.val()?.email) {
                storeUserData(user.val() || {}, thirdPartyData, me?.uid, sessionKey)
            }
            /* if(!user.val()?.thirdPartyData){
                if (thirdPartyData){
                    database.ref(`users/${me?.uid}/thirdPartyData`).set(thirdPartyData).then(()=>{}).catch(()=>{});
                }

            }else if (!thirdPartyData){
                dispatch(slice.actions.setThirdPartyData(user.val()?.thirdPartyData))
            } */
            // console.log("user.val() ", user.val())
            const payload = {
                loggedIn: !!me,// ?.emailVerified,// || !!thirdPartyData,
                me: user.exists()
                    ? {
                        id: me?.uid,
                        emailVerified: me?.emailVerified,
                        email: me?.email, ...user.val(),
                    }
                    : {
                        id: me?.uid,
                        emailVerified: me?.emailVerified,
                        email: me?.email,
                    },
                checked: true,
            }
            // console.log("setMe payload ", payload)
            // fetchSqlData(me?.email, dispatch, payload)
            if (!user.exists()) {
                dispatch(slice.actions.setNewUser(true))
            } else {
                dispatch(slice.actions.setNewUser(false))
            }
            // console.log('userProfileRef user.val() ', user.val())
            // console.log('userProfileRef payload ', payload)

            return dispatch(slice.actions.setMe(payload))
        });
    }else if(thirdPartyData?.email){
        loginWithThirdPartyV2(thirdPartyData, sessionKey, dispatch)
    }else{
        return dispatch(
            slice.actions.setMe({
                loggedIn: false,
                checked: true,
                me: {},
            }),
        )
    }
}

export const authenticateV2 = () => (dispatch, getState) => {
    const sessionKey = getState()?.app?.sessionKey;
    const thirdPartyData = getState()?.app?.thirdPartyData;

    auth.onAuthStateChanged(async (me) => {

        if(sessionKey && me?.uid)
        {
            database.ref(`/sessions/${sessionKey}/id/`).set(me?.uid).then(()=>{}).catch(()=>{});
            database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
        }
        if (!me) {
            dispatch(slice.actions.resetState())
            console.log("!me ", !me)
             dispatch(
                slice.actions.setMe({
                    loggedIn: false,
                    checked: true,
                    me: {},
                }),
            )
        }
        return dispatch(slice.actions.setAuthMe(me))
        /*try{
            if(thirdPartyData?.email){
                if(me?.email && me?.email !== thirdPartyData?.email){
                    auth.signOut().then(data=>{
                        // console.log('user signed out')
                        if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/thirdPartySignOut`).set({
                            thirdParty:true,
                            partnerId:thirdPartyData?.partnerId? parseInt(thirdPartyData?.partnerId) : null,
                            email: thirdPartyData.email || null,
                            previousEmail: me?.email || null
                        }).then(()=>{}).catch((error)=>{console.log("error", error)});
                        if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
                        dispatch(slice.actions.resetState())
                        /!* if(!pathTaken){
                            pathTaken = true
                            console.log("meee run 2")
                            return loginWithThirdParty(thirdPartyData, sessionKey, dispatch);

                        } *!/

                    }).catch(error=>{
                        if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/thirdPartySignOutFailed`).set({
                            thirdParty:true,
                            partnerId:thirdPartyData?.partnerId? parseInt(thirdPartyData?.partnerId) : null,
                            email: thirdPartyData.email || null,
                            previousEmail: me?.email || null,
                            error: error?.message || null
                        }).then(()=>{}).catch((error)=>{console.log("error", error)});
                        if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});

                    })
                    // return  dispatch(slice.actions.setLoggedIn(false));
                }else if(!me){
                    console.log("meee run")
                    if(!pathTaken){
                        pathTaken = true
                        return loginWithThirdPartyV2(thirdPartyData, sessionKey, dispatch)
                    }
                }
            }
            if (!me) {
                dispatch(slice.actions.resetState())
                console.log("!me ", !me)
                dispatch(slice.actions.setChecked(true))
                return dispatch(
                    slice.actions.setMe({
                        loggedIn: false,
                        checked: true,
                        me: {},
                    }),
                )
            }else{

                dispatch(slice.actions.setLoggedIn(true))
            }
            /!* auth.signOut()
            return *!/
            chatListener(me?.uid, dispatch)
            // get user from real time database
            // console.log('userProfileRef', !!userProfileRef)
            /!* else{
              let payload = {
                loggedIn: me?.emailVerified,
                checked: true,
              }Kyle Johnson

              return dispatch(slice.actions.setMe(payload))
            } *!/
            if(me?.uid){
                if(userProfileRef) userProfileRef.off()
                userProfileRef = database.ref(`users/${me?.uid}`)
                userProfileRef.on('value', (user) => {
                    if (!user.val()?.email){
                        storeUserData(user.val() || {}, thirdPartyData, me?.uid, sessionKey )
                    }
                    // console.log("user.val() ", user.val())
                    const payload = {
                        loggedIn: !!me,// ?.emailVerified,// || !!thirdPartyData,
                        me: user.exists()
                            ? {
                                id: me?.uid,
                                emailVerified: me?.emailVerified,
                                email: me?.email, ...user.val(),
                            }
                            : {
                                id: me?.uid,
                                emailVerified: me?.emailVerified,
                                email: me?.email,
                            },
                        checked: true,
                    }
                    // console.log("setMe payload ", payload)
                    // fetchSqlData(me?.email, dispatch, payload)
                    if (!user.exists()) {

                        dispatch(slice.actions.setNewUser(true))
                    } else {
                        dispatch(slice.actions.setNewUser(false))
                    }
                    // console.log('userProfileRef user.val() ', user.val())
                    // console.log('userProfileRef payload ', payload)

                    return dispatch(slice.actions.setMe(payload))

                })
            }
            // login

        }
        catch(error){
            console.log("authenticate error", error)
        }*/
    })
}

export const authenticate = (adsasas, isEmailSignin) => (dispatch, getState) => {
    const sessionKey = getState()?.app?.sessionKey;
    const thirdPartyData = getState()?.app?.thirdPartyData;
    if(isEmailSignin) {
        dispatch(slice.actions.setIsEmailSignIn(isEmailSignin))
        dispatch(slice.actions.setChecked(true))
        return;
    }
    let pathTaken = false
    auth.onAuthStateChanged(async (me) => {

       if(sessionKey && me?.uid)database.ref(`/sessions/${sessionKey}/id/`).set(me?.uid).then(()=>{}).catch(()=>{});
        if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
        try{
            if(thirdPartyData?.email){
                if(me?.email && me?.email !== thirdPartyData?.email){
                    auth.signOut().then(data=>{
                        // console.log('user signed out')
                        if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/thirdPartySignOut`).set({
                            thirdParty:true,
                            partnerId:thirdPartyData?.partnerId? parseInt(thirdPartyData?.partnerId) : null,
                            email: thirdPartyData.email || null,
                            previousEmail: me?.email || null
                        }).then(()=>{}).catch((error)=>{console.log("error", error)});
                        if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
                        dispatch(slice.actions.resetState())
                        /* if(!pathTaken){
                            pathTaken = true
                            console.log("meee run 2")
                            return loginWithThirdParty(thirdPartyData, sessionKey, dispatch);

                        } */

                    }).catch(error=>{
                        if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/thirdPartySignOutFailed`).set({
                            thirdParty:true,
                            partnerId:thirdPartyData?.partnerId? parseInt(thirdPartyData?.partnerId) : null,
                            email: thirdPartyData.email || null,
                            previousEmail: me?.email || null,
                            error: error?.message || null
                        }).then(()=>{}).catch((error)=>{console.log("error", error)});
                        if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});

                    })
                    // return  dispatch(slice.actions.setLoggedIn(false));
                }else if(!me){
                    console.log("meee run")
                    if(!pathTaken){
                        pathTaken = true
                        return loginWithThirdPartyV2(thirdPartyData, sessionKey, dispatch)
                    }
                }
            }
            if (!me) {
                dispatch(slice.actions.resetState())
                console.log("!me ", !me)
                dispatch(slice.actions.setChecked(true))
                return dispatch(
                    slice.actions.setMe({
                        loggedIn: false,
                        checked: true,
                        me: {},
                    }),
                )
            }else{

                dispatch(slice.actions.setLoggedIn(true))
            }
            /* auth.signOut()
            return */
            chatListener(me?.uid, dispatch)
            // get user from real time database
            // console.log('userProfileRef', !!userProfileRef)
            /* else{
              let payload = {
                loggedIn: me?.emailVerified,
                checked: true,
              }Kyle Johnson

              return dispatch(slice.actions.setMe(payload))
            } */
            if(me?.uid){
                if(userProfileRef) userProfileRef.off()
                userProfileRef = database.ref(`users/${me?.uid}`)
                userProfileRef.on('value', (user) => {
                    if (!user.val()?.email){
                         storeUserData(user.val() || {}, thirdPartyData, me?.uid, sessionKey )
                    }
                    // console.log("user.val() ", user.val())
                    const payload = {
                        loggedIn: !!me,// ?.emailVerified,// || !!thirdPartyData,
                        me: user.exists()
                            ? {
                                id: me?.uid,
                                emailVerified: me?.emailVerified,
                                email: me?.email, ...user.val(),
                            }
                            : {
                                id: me?.uid,
                                emailVerified: me?.emailVerified,
                                email: me?.email,
                            },
                        checked: true,
                    }
                    // console.log("setMe payload ", payload)
                    // fetchSqlData(me?.email, dispatch, payload)
                    if (!user.exists()) {

                        dispatch(slice.actions.setNewUser(true))
                    } else {
                        dispatch(slice.actions.setNewUser(false))
                    }
                    // console.log('userProfileRef user.val() ', user.val())
                    // console.log('userProfileRef payload ', payload)

                    return dispatch(slice.actions.setMe(payload))

                })
            }
            // login

        }
        catch(error){
            console.log("authenticate error", error)
        }


    })
}

export const getConversationMessages = (conversationID) => (dispatch) => {

    conversationRef = database.ref(`conversations/${conversationID}`)
    conversationRef.off()
    dispatch(slice.actions.resetMessages())
    conversationRef.on('child_added', (conversationSnap) => {
        if (conversationSnap.exists()) {
            dispatch(slice.actions.addConversationsMessage({
                ...conversationSnap.val(),
                id: conversationSnap.key,
            }))
        }

    })
}

export const getDoctorInfo = (clinicalChat, clinicId, doctorID) => (dispatch) => {
    const node = clinicalChat ? `/clinicStaffMember/${clinicId}/${doctorID}` : `/doctors/${doctorID}`
    database.ref(node).once('value', doctorSnap => {
        if (doctorSnap.exists()) {
            const payload = { ...doctorSnap.val(), id: doctorSnap.key }
            if (payload.fullName) payload.doctorName = payload.fullName
            if (payload.licenseNo) payload.doctorlicense = payload.licenseNo
            if (payload.staffProfile) payload.jobTitle = payload.staffProfile
            return dispatch(slice.actions.setDoctorProfile(payload))
        }
    }).then(()=>{}).catch(()=>{})

}

const fetchOnlineAgentsDetails = (userType, chatType, callback) => {
    const root = `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/fetchOnlineAgent?userType=true&chatType=${chatType? "health-card": "private-pay"}`
    const request = new XMLHttpRequest()
    request.onreadystatechange = (e) => {
        if (request.readyState !== 4) {
            return
        }

        if (request.status === 200) {
            const json = JSON.parse(request.responseText)
            if (json.status === 'OK') {
                const results = json.onlineAgent
                callback(results)
            }
        } else {
            callback(null)
        }
    }

    request.open('POST', root)
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    request.send()
}
const storeChatCategory = (data) => {

}

const transferTier2Listener = (data) => {
    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        url: `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/createTier2queuedTasks`,
        mode: 'cors',
        data: JSON.stringify(data),
    })
        .then((success) => {
            console.log('createTier2queuedTasks success', success)
        })
        .catch((error) => {
            console.log('createTier2queuedTasks error', error)
        })
}

const startChatCall = (sqlThirdPartyData, thirdPartyData,name, email, type, expiry, me_partnerId) =>{
    const {subscription_expiry, available_pay_per_use, payment_type, partner_id}  = sqlThirdPartyData || {}
    const partnerId = thirdPartyData?.partnerId || me_partnerId || null


    const data = {
        "name": name,
        "partnerId": partnerId,
        "email": email,
        "type": String(type).toLowerCase().replace(/_/g, ''),
        "expiry": parseInt(expiry),
        "userId": null
    }
    console.log("data ", data)
    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        url: `https://northamerica-northeast1-yourdoctorsonline-prod.cloudfunctions.net/chatLog`,
        mode: 'cors',
        data: JSON.stringify(data),
    })
        .then((success) => {
            console.log('chatLog success', success)
        })
        .catch((error) => {
            console.log('chatLog error', error)
        })
}
const startPayPerMinuteThread = (conversationId, available_pay_per_use, userId, partnerId,  firebaseId) => {
    axios({
        method: 'GET',
        url: `https://api.yourdoctors.online/patient/thread?chatId=${conversationId}&timeout=${available_pay_per_use}&userId=${userId}&partnerId=${partnerId}&firebaseId=${firebaseId}`,
        mode: 'cors'
    })
        .then((success) => {
            console.log('thread success', success)
        })
        .catch((error) => {
            console.log('thread error', error)
        })
}

export const startPayPerMinute = (openConversationNode, sqlThirdPartyData, thirdPartyData, me) => (dispatch) => {
    const {id, conversations } = me
    const {location} = conversations || {}
    const {available_pay_per_use, partner_id} = sqlThirdPartyData || {}
    const {partnerId} = thirdPartyData || {}
    //startPayPerMinuteThread(location, available_pay_per_use, sqlThirdPartyData.id, partnerId || partner_id,  id)
}

const sendDoctorsFirstMessage = (onlineAgent, conversationId, patientID) => {
    const sendFirstMessageBody = {
        onlineAgent,
        conversation_id: conversationId,
        patient_id: patientID,
    };
    console.log('sendDoctorsFirstMessage sendFirstMessageBody ', sendFirstMessageBody)
    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        url: `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/sendDoctorFirstMessage`,
        mode: 'cors',
        data: JSON.stringify(sendFirstMessageBody),
    })
        .then((main) => {
            console.log('sendDoctorFirstMessage main ', main.data);
        })
        .catch((error) => {
            console.log('sendDoctorFirstMessage error ', error);
        });
}


export const startPatientsChat = (me, issueInfo, selectedClinic, userPatientData, unUsedSqlThirdPartyData, thirdPartyData) => (dispatch, getState) => {
    const sessionKey = getState()?.app?.sessionKey;
    console.log("startPatientsChat triggered ", thirdPartyData)

    const {
        title, clinic_id, trailUsed } = selectedClinic || {}
    const {
        healthInsuranceCardBack,
        healthInsuranceCardFront,
        healthInsuranceCardNumber,
        DOB,
        id,
        name,
        email,
        gender,
        state,
        country,
        type,
        expiry,
        partnerName
    } = me
    const partnerId  = thirdPartyData?.partnerId || me?.partnerId || null
    const updates = {}
    const tier2 = clinic_id !== 'tier1' && !(type && expiry)
    const node = tier2 ? `openClinicalConversations/${clinic_id}` : 'openconversations'
    const conversationID = database.ref(node).push().key
    const messageID = database.ref(`/conversations/${conversationID}`).push().key
    const timeNow = new Date()
    let ageInYears = timeNow - new Date(DOB)
    ageInYears /= (1000 * 3600 * 24 * 365)
    ageInYears = Math.floor(ageInYears)
    const userData = {
        location: conversationID,
        doctorID: null,
        isEMRChat: partnerId? false : !!(healthInsuranceCardBack || healthInsuranceCardFront || healthInsuranceCardNumber),
        issue: issueInfo?.issue,
        symptoms: issueInfo?.issue,
        category: issueInfo?.symptom? issueInfo.symptom[0]: "Other",
        categories: issueInfo?.symptom? issueInfo.symptom.join(','): "Other",
        callEnabled: true,
        clinical_chat: false,
        clinic_id: null,
        tier2: false,
    }
    const openConversationData = {
        status: true,// set it as it is
        patientID: id,
        timestamp: timeNow.getTime(),
        isEMRChat: partnerId? false: !!(healthInsuranceCardBack || healthInsuranceCardFront || healthInsuranceCardNumber),
        paidChat: true,
        issue: issueInfo?.issue,
        category: issueInfo?.symptom? issueInfo.symptom[0]: "Other",
        categories: issueInfo?.symptom? issueInfo.symptom.join(','): "Other",
        clinical_chat: false,
        patientName: name,
        clinical_location: null,
        clinical_to_skip: false,// set it as it is
        partner_id: partnerId?parseInt(partnerId) : null,
        thirdPartyPaymentType: type || null,
        expiry: type? parseInt(expiry || 0):null,
        userId: null,
        callEnabled: true,
        email
    }

    const messageObj = {
        type: 'text',
        timestamp: timeNow.getTime(),
        content: `Hello Doctor! My name is ${name}. \nGender is ${gender}.\nAge is ${ageInYears}.\nMy main issue is: ${issueInfo?.issue}`,
        fromID: id,
        isRead: false,
    }
    const chatCategoryData = {
        chatCategory: {
            age: DOB,
            category: issueInfo?.symptom? issueInfo.symptom[0]: "Other",
            gender,
            issue: issueInfo?.issue,
            medical_details: '',
            name,
            patient_id: userPatientData?.id,
            question: 'none',
            chat_id: conversationID,
        },
    }
    let sessionParams = {
        tier2,
        email,
        title:title || null,
        id: id || null,
        isThirdParty: !!(type && expiry),
        state: state || null,
        country: country || null,
        trailUsed: trailUsed || false
    }
    if(type && expiry){
        const time= Date.now();
        updates[`partnerLogs/${id}/${time}/event`] = "chat_initiated";
        updates[`partnerLogs/${id}/${time}/conversationID`] = conversationID;
        updates[`partnerLogs/${id}/${time}/currentValue`] = parseInt(expiry || 0);
        updates[`partnerLogs/${id}/${time}/partnerName`] = partnerName;
    }
    if (tier2) {
        userData.clinical_chat = true
        openConversationData.clinical_chat = true
        userData.clinic_id = clinic_id
        openConversationData.clinical_location = state || null
        userData.tier2 = true
        userData.tier2ClinicData = selectedClinic
        openConversationData.queued = true
        openConversationData.doctorID = null
        userData.doctorID = null
        updates[`${node}/${conversationID}/`] = openConversationData
        updates[`/users/${id}/conversations/`] = userData
        updates[`/users/${id}/symptoms/`] = issueInfo?.issue
        updates[`/conversations/${conversationID}/${messageID}/`] = messageObj
        console.log('tier2 start chat updates ', updates)
        database.ref().update(updates).then(data => {
            dispatch(setIssueInfo(null))
            dispatch(setSelectedClinic(null))
            if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/startChat`).set(sessionParams).then(()=>{}).catch((error)=>{console.log("error", error)});
            if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
        }).catch(error=>{
            console.log('tier2 start chat updates error', error)
            if(error?.message)sessionParams.error = error?.message
            if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/startChatError`).set(sessionParams).then(()=>{}).catch((error)=>{console.log("error", error)});
            if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
        })
        chatCategoryData.chatCategory.Doctor_Name = ''
        storeChatCategory(JSON.stringify(chatCategoryData))
        transferTier2Listener({
            clinic_id,
            conversation_id: conversationID,
        })
    }
    else {
        fetchOnlineAgentsDetails(true, !!(healthInsuranceCardBack || healthInsuranceCardFront || healthInsuranceCardNumber), (data) => {
            console.log('fetchOnlineAgentsDetails ', data)
            openConversationData.queued = !data?.doctorID
            openConversationData.doctorID = data?.doctorID || null
            userData.doctorID = data?.doctorID || null
            messageObj.toID = data?.doctorID || null
            updates[`${node}/${conversationID}`] = openConversationData
            updates[`/users/${id}/symptoms/`] = issueInfo?.issue
            updates[`/users/${id}/conversations`] = userData
            updates[`/conversations/${conversationID}/${messageID}`] = messageObj
            console.log('start chat updates ', updates)
            database.ref().update(updates).then(data => {
                dispatch(setIssueInfo(null))
                dispatch(setSelectedClinic(null))
                //if(error?.message)sessionParams.error = error?.message
                if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/startChat`).set(sessionParams).then(()=>{}).catch((error)=>{console.log("error", error)});
                if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});

            }).catch(error=>{
                console.log('start chat updates error', error)
                if(error?.message)sessionParams.error = error?.message
                if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/startChatError`).set(sessionParams).then(()=>{}).catch((error)=>{console.log("error", error)});
                if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
            })
             if(type && expiry){
                // if(!(type === "payperuse" && String(partnerId)) === "7")startChatCall(sqlThirdPartyData, thirdPartyData, name, email, type, expiry, partnerId)
                // if(data?.doctorID && (payment_type === "payperminute" || payment_type === "pay_per_minute"))startPayPerMinuteThread(conversationID, available_pay_per_use, sqlThirdPartyData.id, partner_id || partnerId,  id)
            }
            console.log('start chat data ', data)
            if(data?.doctorID)sendDoctorsFirstMessage(data, conversationID, id)
            chatCategoryData.chatCategory.Doctor_Name = data?.doctorID ? `${data.doctorID},` : ''
            storeChatCategory(JSON.stringify(chatCategoryData))
        })
    }
}

const signup = ({ fullName, email, password }) => () =>
    new Promise(async (resolve, reject) => {
        try {
            // create user
            const { user } = await auth.createUserWithEmailAndPassword(
                email,
                password,
            )

            // send confirmation email
            await user.sendEmailVerification()

            /* // store user info in firestore
             await firestore.collection('users').doc(user.uid).set({
               fullName,
               email,
             }) */

            resolve(user)
        } catch (err) {
            reject(err)
        }
    })

const login = ({ email, password }) => (dispatch) =>
    new Promise(async (resolve, reject) => {
        try {
            const { user } = await auth.signInWithEmailAndPassword(email, password)
            if (!user) reject(new Error('Failed to login. please try it again later'))
            if (!user.emailVerified) await user.sendEmailVerification()
            dispatch(authenticate())
            resolve(user)
        } catch (err) {
            reject(err)
        }
    })

const socialLogin = (provider, userToken) => (dispatch) =>
    new Promise(async (resolve, reject) => {
        console.log('socialLogin provider', provider)
        console.log('socialLogin userToken', userToken)

        try {
            let credential
            switch (provider) {
                case 'facebook':
                    credential =
                        auth.FacebookAuthProvider.credential(userToken.accessToken)
                    break
                case 'google':
                    credential = firebase.auth.GoogleAuthProvider.credential(userToken.idToken, userToken.accessToken)
                    break
                default:
                    break
            }
            console.log('socialLogin credential', credential)
            const data = auth.signInWithCredential(credential)
            const { uid } = data
            console.log('socialLogin data', data)
            if (!uid) reject(new Error('Failed to login. please try it again later'))
            // if (!user.emailVerified) await user.sendEmailVerification()
            dispatch(authenticate())
            resolve(uid)
        } catch (err) {
            reject(err)
        }
    })

const logout = () => (dispatch) =>
    new Promise(async (resolve, reject) => {
        try {
            auth.signOut().then(()=>{
                console.log("signout success")
                dispatch(slice.actions.resetState())
                let newSessionKey = database.ref('/sessions/').push().key || null
                dispatch(slice.actions.setSessionKey(newSessionKey))
                if(newSessionKey)database.ref(`/sessions/${newSessionKey}/timestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
                if(newSessionKey)database.ref(`/sessions/${newSessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});

                dispatch(
                    slice.actions.setMe({
                        checked: true,
                        loggedIn: false,
                        me: {},
                    }),
                )
                resolve()
            }).catch(error=>{
                console.log("logout err ", error)
                reject(error)
            })


        } catch (err) {
            console.log("logout err ", err)
            reject(err)
        }
    })

const resetPassword = (email) => () => auth.sendPasswordResetEmail(email)

// ------------------------------------
// Exports
// ------------------------------------

export const actions = {
    ...slice.actions,
    setShowDownloadApp,
    authenticate,
    authenticateV2,
    signup,
    login,
    logout,
    resetPassword,
    socialLogin,
    startChat,
    getConversationMessages,
    getDoctorInfo,
    setSocialInfo,
    setOpenConversations,
    setQueueTime,
    setSelectedClinic,
    setClinics,
    startPatientsChat,
    setStripeCustomer,
    setThirdPartyData,
    setThirdPartyHandled,
    setSkipHealthCard,
    startPayPerMinute,
    getSQLThirdPartyData,
    setSessionKey,
    setPharmaInfo,
    setCampaignSource,
    setCloseChat,
    setupLogin,
}

export default slice.reducer
