import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { loadFontIcons } from 'components/FontIcon'
import store from 'utils/store'
import { PersistGate } from 'redux-persist/integration/react'
//import { persistStore } from 'redux-persist'
import Router from './routes'
import Spinner from './components/Spinner'
import classes from './pages/auth/auth.module.scss'
import LoadingScreen from './components/LoadingScreen/LoadingScreen'
import { database } from './utils/firebase'

function App() {

    useEffect(() => {
        loadFontIcons()
        console.log("window.location ", window.location)

    }, [])
    const urlParams = window.location.search;
    //const persistor = persistStore(store)

    return (
        <Provider store={store}>
                <div data-testid='app' className='app'>
                    <Router urlParams={urlParams}/>
                </div>
        </Provider>
    )
}

export default App
