import logo from 'assets/images/logo.svg'
import profile from 'assets/images/profile.png'
import illustrationDoc from 'assets/images/illustration_doc.svg'
import FbIcon from 'assets/images/fb.png'
import GoogleIcon from 'assets/images/Google.png'
import AppleIcon from 'assets/images/icon_apple.svg'
import healthCardFront from 'assets/images/member_card_front.svg'
import healthCardBack from 'assets/images/member_card_back.svg'
import checkOn from 'assets/images/check_on.svg'
import checkOff from 'assets/images/check_off.svg'
import attachmentIcon from 'assets/images/attahcment.svg'
import sendButtonIcon from 'assets/images/send_msg.png'
import attachmentTPIcon from 'assets/images/attahcment_tp.svg'
import sendButtonTPIcon from 'assets/images/send_msg_tp.png'
import videoButton from 'assets/images/icon_play_video.svg'
import prescriptionIcon from 'assets/images/prescription.svg'
import medicationIcon from 'assets/images/medication.svg'
import genderMale from 'assets/images/icon_male_enabled.svg'
import genderFemaleDisabled from 'assets/images/icon_female_disabled.svg'
import pdfIcon from 'assets/images/pdf_icon.png'
import GPIcon from 'assets/images/general_practitioner_icon.svg'
import DownloadImage from 'assets/images/download_image.png'
import continueArrow from  'assets/images/continue-arrow.png'
import removeIcon from 'assets/images/remove.svg'
import downloadQRCode from 'assets/images/QR-YDO.png'

export const images = {
    logo,
    profile,
    illustrationDoc,
    FbIcon,
    GoogleIcon,
    AppleIcon,
    healthCardFront,
    healthCardBack,
    checkOn,
    checkOff,
    attachmentIcon,
    attachmentTPIcon,
    sendButtonIcon,
    sendButtonTPIcon,
    videoButton,
    prescriptionIcon,
    medicationIcon,
    genderMale,
    genderFemaleDisabled,
    pdfIcon,
    GPIcon,
    DownloadImage,
    continueArrow,
    removeIcon,
    downloadQRCode
}

export default {}
