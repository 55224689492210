import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import app from 'slices/app.slice'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'

const persistConfig = {
    key: 'root',
    storage,
    throttle: 500,
    version: 1,
}
const reducers = combineReducers({
    app,
})

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
    reducer: persistedReducer,
    middleware:
        process.env.NODE_ENV === 'production'
            ? [...getDefaultMiddleware({ serializableCheck: false })]
            : [...getDefaultMiddleware({ serializableCheck: false }), logger],
})

export default store
