export const path = {
    login: '/login',
    signin: '/signin',
    emailLink: '/email_link',
    profile: '/profile',
    resetPassword: '/reset-password',
    confirmEmail: '/confirm-email',
    dashboard: '/dashboard',
    chat: '/chat',
    downloadApp: '/downloadApp',
    paymentConfirmation: '/payment_confirmation',
    signInSuccess: "/sign-in-success"
}
