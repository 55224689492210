import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/performance'
import 'firebase/compat/analytics'
import 'firebase/compat/database'
import 'firebase/compat/remote-config'
//import 'firebase/compat/app-check'
import config from './config'

/*if (process.env.NODE_ENV === 'development') {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN=true;

}*/
firebase.initializeApp(config.firebase)
/*
const appCheck = firebase.appCheck();

appCheck.activate(
    new firebase.appCheck.ReCaptchaEnterpriseProvider(process.env.REACT_APP_APP_CHECK_KEY),
    true // Set to true to allow auto-refresh.
);
*/

export const auth = firebase.auth()
export const database = firebase.database()

export const storage = firebase.storage()
export const storageRef = firebase.storage
export const performance = firebase.performance()
export const firestore = firebase.firestore()
export const remoteConfig = firebase.remoteConfig()
remoteConfig.settings.fetchTimeMillis = 60000
remoteConfig.settings.minimumFetchIntervalMillis = 300000
remoteConfig.defaultConfig = ({
    'all_issues': "COVID-19,Sexual Health,Pregnancy,Cold or Flu,Gastrointestinal,Skin,Children's Health,Other\n"
});
remoteConfig.ensureInitialized().then(data=>remoteConfig.fetchAndActivate().then(data=>{})).catch(error=>remoteConfig.activate())

export default firebase
