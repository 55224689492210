import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { actions } from 'slices/app.slice'
import { path } from 'utils/const'
import Fallback from 'components/Fallback'
import Spinner from 'components/Spinner'
import styles from './pages/auth/auth.module.scss'
import { images } from './assets'
import {  database } from './utils/firebase'

const ChatScreen = React.lazy(() => import('./pages/chatScreen/ChatScreen'))
const DownloadAppScreen = React.lazy(() => import('./pages/downloadApp'))
const StripeConfirmation = React.lazy(() => import('./pages/dashboard/StripeConfirmation'))
const SignInSuccess = React.lazy(() => import('./pages/SignInSuccess/SignInSuccess'))
const Auth = React.lazy(() => import('./pages/auth'))
const Dashboard = React.lazy(() => import('./pages/dashboard'))

function Router(props) {
    const dispatch = useDispatch()
    const {
        checked,
        loggedIn,
        startChat,
        openConversationNode,
        queued,
        issueInfo,
        sqlThirdPartyData,
        thirdPartyData,
        sqlData,
        sessionKey,
        me,
        pharmaInfo,
        isEmailSignIn,
        campaignSource,
        closeChat,
        authMe
    } = useSelector((state) => state.app)
    const [currentConvoData, setCurrentConvoData] = useState(null)
    const [isThirdParty, setIsThirdParty] = useState(false)

    useEffect(() => {
        if (authMe) {
            dispatch(actions.setupLogin(authMe))
        }
    }, [authMe])
    useEffect(()=>{
        dispatch(actions.setClinics(null))
    },[])
    useEffect(()=>{

        if(thirdPartyData || authMe){
            dispatch(actions.setupLogin(authMe));
        }
    },[thirdPartyData, authMe])
    useEffect(() => {
        try{
            // auth.currentUser.sendEmailVerification().then(data=>console.log("sendEmailVerification data", data)).catch(error=>console.log("sendEmailVerification error", error))
            console.log("route init")
            dispatch(actions.authenticateV2());
           /* database.ref(`archiveConversations`).orderByChild('timestamp').startAfter(1646870400000).endBefore(1646956800000).once('value', data=>{
                console.log("data.count", data.numChildren())
            }) */
            if(!sessionKey){
                const newSessionKey = database.ref('/sessions/').push().key || null;
                dispatch(actions.setSessionKey(newSessionKey))
                if(newSessionKey)database.ref(`/sessions/${newSessionKey}/timestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
                if(newSessionKey)database.ref(`/sessions/${newSessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
            }
            const urlParams = new URLSearchParams(window.location.search || props.urlParams)

            if (urlParams.has('partnerId')) {
                console.log("partnerId authenticate triggered")
                const authorizationKey = urlParams.get('authorizationKey')
                const partnerName = urlParams.get('partnerName')
                const partnerId = urlParams.get('partnerId')? parseInt(urlParams.get('partnerId')) : null
                const email = urlParams.get('email')
                const name = urlParams.get('name')
                const bloodGroup = urlParams.get('bloodGroup')
                const notes = urlParams.get('notes')
                const gender = urlParams.get('gender')
                const dateOfBirth = urlParams.get('dateOfBirth')
                const type = String(urlParams.get('type')).toLowerCase().replace(/_/g, '')
                const expiry = urlParams.get('expiry')
                const phoneNumber = urlParams.get('phoneNumber')
                const address = urlParams.get('address')
                const city = urlParams.get('city')
                const state = urlParams.get('state')
                const country = urlParams.get('country')
                const postalCode = urlParams.get('postalCode')
                const userID = urlParams.get('userID')
                const fcmToken = urlParams.get('fcmToken')
                const newThirdPartyData = {
                    authorizationKey,
                    partnerName,
                    partnerId,
                    email,
                    name,
                    bloodGroup,
                    notes,
                    gender,
                    dateOfBirth,
                    type,
                    expiry,
                    phoneNumber,
                    address,
                    city,
                    state,
                    country,
                    postalCode,
                    partnerFCMToken: fcmToken,
                    partnerUserID: userID
                }
                console.log("newThirdPartyData ", newThirdPartyData)
                dispatch(actions.setThirdPartyData(newThirdPartyData))
            }
            else if (urlParams.has('pharmaName')) {
                console.log("pharmaName triggered")
                const pharmaName = urlParams.get('pharmaName')
                const faxNumber = urlParams.get('faxNumber')
                dispatch(actions.setPharmaInfo({ name: pharmaName, faxNumber }))

            }
            else if (urlParams.has('source')) {
                console.log("campaign source triggered")
                const source = urlParams.get('source')
                dispatch(actions.setCampaignSource({ source, timestamp: Date.now() }))
                dispatch(actions.authenticateV2())
            }
            else {
                console.log("default authenticate triggered")
                dispatch(actions.authenticateV2())
            }
            /* const data= {
               phoneNumberTo: "+923228055287",
               messageText: "Ontario"
           }
           console.log('email registration runs');
           axios({
               method: 'POST',
               headers: {
                   'Content-Type': 'application/json',
               },
               url: `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/sendTextMessage`,
               mode: 'cors',
               data: JSON.stringify(data),
           })
               .then(res=>{
                   console.log("sendTextMessage res ", res)
               })
               .catch(error=>{
                   console.log("sendTextMessage error ", error)
               }) */
        }
        catch(error){
            console.log("route error", error)
        }

    }, [])

    useEffect(()=>{
        if(sessionKey && thirdPartyData?.partnerId)database.ref(`/sessions/${sessionKey}/partnerId/`).set(thirdPartyData?.partnerId).then(()=>{}).catch(()=>{});
        if(sessionKey && thirdPartyData?.partnerName)database.ref(`/sessions/${sessionKey}/partnerName/`).set(thirdPartyData?.partnerName).then(()=>{}).catch(()=>{});
        if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
    },[sessionKey, thirdPartyData?.partnerId])

    useEffect(()=>{
        if(sessionKey && pharmaInfo?.name)database.ref(`/sessions/${sessionKey}/pharmacy/`).set(pharmaInfo?.name).then(()=>{}).catch(()=>{});
        if(sessionKey && pharmaInfo?.faxNumber)database.ref(`/sessions/${sessionKey}/pharmacyFaxNumber/`).set(pharmaInfo?.faxNumber).then(()=>{}).catch(()=>{});
        if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
    },[sessionKey, pharmaInfo?.name])
    useEffect(()=>{
        if(me?.id && campaignSource?.source)database.ref(`/users/${me?.id}/sources/${campaignSource?.source}`).set(campaignSource?.timestamp || Date.now()).then(()=>{}).catch(()=>{});
    },[me?.id, campaignSource?.source])
    useEffect(()=>{
        if(sessionKey) database.ref(`/sessions/${sessionKey}/platform/`).set('web').then(()=>{}).catch(()=>{});
        if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
    },[sessionKey])
    useEffect(() => {
        if (currentConvoData !== openConversationNode) {
            if ((currentConvoData?.status === true && !openConversationNode?.status)) {
                console.log("chatClosed  pharmaInfo ", pharmaInfo)
                if(!pharmaInfo?.name)dispatch(actions.setCloseChat(true))
                if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}/chatClosed`).set({
                    email: me?.email,
                    id: me?.id,
                    conversationId: currentConvoData?.id
                }).then(()=>{}).catch((error)=>{console.log("error", error)});
                if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
                if(pharmaInfo?.name) dispatch(actions.logout())
                dispatch(actions.resetMessages())
                /* dispatch(actions.setOpenConversation(null))
                dispatch(actions.setQueueChatStatus(false))
                dispatch(actions.setStartChat(false)) */
            }
            setCurrentConvoData(openConversationNode)
            if(openConversationNode?.queued === true && openConversationNode.queued === false){
                if(String(openConversationNode?.thirdPartyPaymentType).toLowerCase().replace(/_/g, '')  === "payperminute"){
                    dispatch(actions.startPayPerMinute(openConversationNode, sqlThirdPartyData, thirdPartyData, me))
                }
            }
        }
    }, [openConversationNode])

    useEffect(()=>{
        setIsThirdParty(me?.partnerId || thirdPartyData?.partnerId )
    },[thirdPartyData?.partnerId, me?.partnerId  ])

    if (!checked) {
        return (
            <div className='app-loader-container'>
                <Spinner size='4rem' color='white' isLoading />
            </div>
        )
    }


    return (
        <BrowserRouter>
            <Suspense fallback={<Fallback />}>
                <div className={styles.root}>
                    <div className={`${styles.leftContainer} ${isThirdParty? 'd-none': ''}`}>

                        {/* {pharmaInfo && me?.id && <div className={styles.userRow}><span  className={styles.userName}>Hi <span className={styles.primary}>Shayan Shokat</span>!</span>
                            <Button type='submit' onClick={() => {}} className={styles.logoutButton}>LOGOUT</Button></div>} */}

                        <div className={styles.logo_header} onClick={()=>{
                            if(sessionKey)database.ref(`/sessions/${sessionKey}/events/${Date.now()}`).set('downloadAppTriggeredFromHeader').then(()=>{}).catch((error)=>{console.log("error", error)});
                            if(sessionKey)database.ref(`/sessions/${sessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
                            window.open("https://yourdoctors.online/download-app/", '_blank').focus();
                        }}>
                            <img src={images.logo} className={styles.logo}
                                 alt='logo' />
                            <span
                                className={styles.title}>Your Doctors Online</span>
                        </div>
                        <div className={styles.illustrationDocDiv}>
                            <img src={images.illustrationDoc}
                                 alt='illustration doc' />
                        </div>
                        {/* <div></div> */}

                    </div>
                    {isThirdParty? loggedIn ?(startChat && openConversationNode && !openConversationNode?.queued) ? (
                            <Switch>
                                <Route path={path.chat}>
                                    <ChatScreen isThirdParty={isThirdParty} />
                                </Route>
                                <Redirect to={path.chat} />
                            </Switch>) : <Switch>
                        <Route path={path.dashboard}>
                            <Dashboard isThirdParty={isThirdParty}/>
                        </Route>
                        <Redirect to={path.dashboard} />
                    </Switch>:<Switch>
                        <Route path='/'>
                            <Auth isThirdParty={isThirdParty} />
                        </Route>
                        <Redirect to='/' />
                    </Switch>:closeChat && !isThirdParty? <Switch>
                            <Route path={path.downloadApp}>
                                <DownloadAppScreen isThirdParty={isThirdParty}/>
                            </Route>
                            <Redirect to={path.downloadApp} />
                        </Switch>
                        : (startChat && openConversationNode && !openConversationNode?.queued) ? (
                            <Switch>
                                <Route path={path.chat}>
                                    <ChatScreen isThirdParty={isThirdParty} />
                                </Route>
                                <Redirect to={path.chat} />
                            </Switch>) : (
                            <div className={styles.rightContainer}>
                                {!loggedIn && issueInfo ? isEmailSignIn? (
                                    <Switch>
                                        <Route path='/'>
                                            <Switch>
                                                <Route path={path.signInSuccess}>
                                                    <SignInSuccess isEmailSignIn={isEmailSignIn}/>
                                                </Route>
                                                <Redirect to={path.signInSuccess} />
                                            </Switch>
                                        </Route>
                                        <Redirect to='/' />
                                    </Switch>
                                ): (
                                    <Switch>
                                        <Route path='/'>
                                            <Auth isThirdParty={isThirdParty} />
                                        </Route>
                                        <Redirect to='/' />
                                    </Switch>
                                ) : (
                                    <Switch>
                                        <Route path={path.dashboard}>
                                            <Dashboard isThirdParty={isThirdParty}/>
                                        </Route>
                                        <Route path={path.paymentConfirmation}>
                                            <StripeConfirmation isThirdParty={isThirdParty}/>
                                        </Route>
                                        <Redirect to={path.dashboard} />
                                    </Switch>
                                )}
                            </div>)

                    }



                </div>
            </Suspense>

        </BrowserRouter>
    )
}

export default Router
